<template>
  
  <div v-if="show==false">
    <router-view />
  </div>
  <div v-else>
    <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
  </div>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
  },
  watch:{
      $route (to, from){
          console.log('route changed')
          console.log(to)
          console.log('from')
          console.log(from)

          if(to.name=='personnalisation' || to.name=='personnalisation-meyro' )
          {
            this.show=false
          }
          else {
            this.show=true
          }
      }
  } ,
  data() {
    return {
      show:true
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  mounted() {
    console.log('layout mounted')
    console.log(this.$route.name)

    if(this.$route.name=='personnalisation' || this.$route.name=='personnalisation-meyro')
    {
      this.show=false
    }
  }

}
</script>
